import { useEffect, useState } from "react";
var getSize = () => {
  var safeAreaInsetTop = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top')) || 0; // Fallback value
  var safeAreaInsetBottom = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom')) || 0; // Fallback value
  var safeAreaInsetLeft = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-left')) || 0;
  var safeAreaInsetRight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-right')) || 0;
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
    safeAreaInsetTop,
    safeAreaInsetBottom,
    safeAreaInsetLeft,
    safeAreaInsetRight
  };
};
export var WindowSizeContextProvider = {};

/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
export function useWindowSize() {
  var MOBILE_SIZE_THRESHOLD = 1120;
  var SMARTPHONE_SIZE_THRESHOLD = 640;
  var [windowSize, setWindowSize] = useState(getSize());
  var [isMobile, setIsMobile] = useState(getSize().innerWidth < MOBILE_SIZE_THRESHOLD);
  var [isSmartphone, setIsSmartphone] = useState(getSize().innerWidth < SMARTPHONE_SIZE_THRESHOLD);
  var [safeAreaInsetTop, setSafeAreaInsetTop] = useState(getSize().safeAreaInsetTop);
  var [safeAreaInsetBottom, setSafeAreaInsetBottom] = useState(getSize().safeAreaInsetBottom);
  var [safeAreaInsetLeft, setSafeAreaInsetLeft] = useState(getSize().safeAreaInsetLeft);
  var [safeAreaInsetRight, setSafeAreaInsetRight] = useState(getSize().safeAreaInsetRight);
  useEffect(() => {
    setIsSmartphone(windowSize.innerWidth < SMARTPHONE_SIZE_THRESHOLD);
    setIsMobile(windowSize.innerWidth < MOBILE_SIZE_THRESHOLD);
    setSafeAreaInsetTop(windowSize.safeAreaInsetTop);
    setSafeAreaInsetBottom(windowSize.safeAreaInsetBottom);
    setSafeAreaInsetLeft(windowSize.safeAreaInsetLeft);
    setSafeAreaInsetRight(windowSize.safeAreaInsetRight);
  }, [windowSize.innerWidth]);
  var handleResize = () => {
    var newSize = getSize();

    // Check if something has changed
    for (var x in windowSize) if (windowSize[x] !== newSize[x]) {
      setWindowSize(getSize());
      break;
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  return {
    windowSize,
    isMobile,
    isSmartphone,
    safeAreaInsetBottom,
    safeAreaInsetTop,
    safeAreaInsetLeft,
    safeAreaInsetRight
  };
}