export const env = {
    store_view_code: process.env.STORE_VIEW_CODE,
    language: process.env.LANGUAGE,
    consentApiUrl: process.env.CONSENT_API_URL,
    WEBSITE_CODE: process.env.WEBSITE_CODE,
    WEBSITE_LANGUAGE: process.env.WEBSITE_LANGUAGE,
    MISCUSI_ENV: process.env.MISCUSI_ENV,
    MAGENTO_BACKEND_URL: process.env.MAGENTO_BACKEND_URL,
    URL_TAKEAWAY: process.env.URL_TAKEAWAY,
    URL_MISCUSI: process.env.URL_MISCUSI,
    CHECKPLEASE_FIREBASE_API_KEY: process.env.CHECKPLEASE_FIREBASE_API_KEY,
    CHECKPLEASE_FIREBASE_AUTH_DOMAIN: process.env.CHECKPLEASE_FIREBASE_AUTH_DOMAIN,
    CHECKPLEASE_FIREBASE_DATABASE_URL: process.env.CHECKPLEASE_FIREBASE_DATABASE_URL,
    CHECKPLEASE_FIREBASE_PROJECT_ID: process.env.CHECKPLEASE_FIREBASE_PROJECT_ID,
    CHECKPLEASE_FIREBASE_APP_ID: process.env.CHECKPLEASE_FIREBASE_APP_ID,
    CHECKPLEASE_STORAGE_BUCKET: process.env.CHECKPLEASE_STORAGE_BUCKET,
    CHECKPLEASE_MESSAGING_SENDER_ID: process.env.CHECKPLEASE_MESSAGING_SENDER_ID,
    CHECKPLEASE_SERVER_URL: process.env.CHECKPLEASE_SERVER_URL,
    CHECKPLEASE_V2_SERVER_URL: process.env.CHECKPLEASE_V2_SERVER_URL,
    SATISPAY_IFRAME_HOST: process.env.SATISPAY_IFRAME_HOST,
    VAPID_KEY: process.env.VAPID_KEY,
    GA_CODE: process.env.GA_CODE,
    GOOGLE_ADS_CODE: process.env.GOOGLE_ADS_CODE,
    DISABLE_COOKIE_BANNER: process.env.DISABLE_COOKIE_BANNER !== 'false', // Assuming default is true unless explicitly set to false
    API_KEY: process.env.API_KEY,
    WEGLOT_API_KEY: process.env.WEGLOT_API_KEY,
    ADYEN_ENV: process.env.ADYEN_ENV,
    ADYEN_CLIENT_KEY: process.env.ADYEN_CLIENT_KEY,
    ADYEN_MERCHANT_NAME: process.env.ADYEN_MERCHANT_NAME,
    ADYEN_MERCHANT_ID: process.env.ADYEN_MERCHANT_ID,
    GOOGLE_MERCHANT_ID: process.env.GOOGLE_MERCHANT_ID,
    GOOGLE_PAY_ENV: process.env.GOOGLE_PAY_ENV,
    IMAGE_BASE_URL: process.env.IMAGE_BASE_URL,
};
