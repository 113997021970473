import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAppContext } from '@overrides_venia/peregrine/context/app';
import { storesArray } from './storeConfigs';
import { GET_STORES } from "@Src_main/pages/Stores/stores.gql";
import { GET_TABLE_MAP } from '@Components/ModalPickupPoint/tableMap.gql';
import { useCheckoutContext } from '@magento/peregrine/lib/context/checkout';
export function useStores() {
  var [{
    stores
  }, {
    actions: {
      setStores
    }
  }] = useAppContext();
  var [{
    tableData
  }] = useCheckoutContext();
  var [tableMap, setTableMap] = useState([]);
  var [tableNumber, setTableNumber] = useState(0);
  var [getStores, {
    data,
    loading
  }] = useLazyQuery(GET_STORES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      var enrichedStores = storesArray.map(x => {
        var storesData = data.stores.find(y => y.ls_store_id === x.id);
        if (storesData !== undefined) {
          return _objectSpread(_objectSpread({}, x), storesData);
        }
      }).filter(x => x !== undefined);
      setStores(enrichedStores);
    }
  });
  var [getTableMap] = useLazyQuery(GET_TABLE_MAP, {
    fetchPolicy: "no-cache",
    variables: {
      restaurant_id: tableData.cps
    },
    onCompleted: data => {
      setTableMap(data.restaurantTable);
    }
  });
  useEffect(() => {
    if (tableMap && tableData.cpt) {
      var _tableMap$find;
      var tableNum = (_tableMap$find = tableMap.find(x => x.table_id === tableData.cpt)) === null || _tableMap$find === void 0 ? void 0 : _tableMap$find.table_number;
      if (tableNum) {
        setTableNumber(tableNum);
      }
    }
  }, [tableData.cpt, tableMap]);
  useEffect(() => {
    if (stores.length === 0) {
      getStores();
    }
  }, []);
  useEffect(() => {
    if (tableData.cps) {
      getTableMap();
    }
  }, [tableData.cps]);
  return {
    stores,
    tableMap,
    tableNumber
  };
}
;
export default useStores;