import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
import { gql } from "@apollo/client";
import { CheckoutPageFragment } from "./checkoutPageFragments.gql";
import { OrderConfirmationPageFragment } from "@magento/venia-ui/lib/components/CheckoutPage/OrderConfirmationPage/orderConfirmationPageFragments.gql";
export var CREATE_CART = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    # This mutation will return a masked cart id. If a bearer token is provided for\n    # a logged in user it will return the cart id for that user.\n    mutation createCart {\n        cartId: createEmptyCart\n    }\n"])));
export var PLACE_ORDER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation placeOrder($cartId: String!) {\n        placeOrder(input: { cart_id: $cartId }) {\n            order {\n                order_number\n                ls_document_id\n                ls_queue_counter\n            }\n        }\n    }\n"])));
export var PLACE_ORDER_ADYEN = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation placeOrder($cartId: String!) {\n        placeOrder(input: { cart_id: $cartId }) {\n            order {\n                order_number\n                ls_document_id\n                ls_queue_counter\n                adyen_payment_status {\n                    isFinal\n                    resultCode\n                    additionalData\n                    action\n                }\n            }\n            \n        }\n    }\n"])));
export var TAKEAWAY_DATA_ON_CART = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation setTakeawayDataOnCart(\n        $cart_id: String!\n        $pickup_date: String!\n        $pickup_time: String!\n        $ls_store_id: String!\n        $ls_covers_quantity: Int\n        $ls_sales_type: String\n        $ls_table_number: String\n    ) {\n        setTakeawayDataOnCart(\n            input: {\n                cart_id: $cart_id\n                pickup_date: $pickup_date\n                pickup_time: $pickup_time\n                ls_store_id: $ls_store_id\n                ls_covers_quantity: $ls_covers_quantity\n                ls_sales_type: $ls_sales_type\n                ls_table_number: $ls_table_number\n            }\n        ) {\n            cart {\n                id\n                hospitality_data {\n                    ls_covers_quantity\n                    ls_sales_type\n                    ls_table_number\n                    pickup_date\n                    pickup_time\n                    pickup_store\n                }\n            }\n        }\n    }\n"])));
export var REMOVE_LS_LOYALTY_POINTS = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    mutation remove_ls_LoyaltyPoints($cart_id: String!) {\n        removeLsLoyaltyPoints(input: { cart_id: $cart_id }) {\n            cart {\n                prices {\n                    grand_total {\n                        currency\n                        value\n                    }\n                }\n                items {\n                    uid\n                    product {\n                        sku\n                        stock_status\n                    }\n                    quantity\n                }\n                applied_coupons {\n                    code\n                }\n                applied_gift_card {\n                    amount\n                    code\n                }\n                loyalty_points_info {\n                    point_rate\n                    points_earn\n                    points_spent\n                    points_discount\n                }\n            }\n        }\n    }\n"])));
export var APPLY_LS_LOYALTY_POINTS = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    mutation applyLsLoyaltyPoints($cart_id: String!, $loyalty_points: Float!) {\n        applyLsLoyaltyPoints(input: { cart_id: $cart_id, loyalty_points: $loyalty_points }) {\n            cart {\n                prices {\n                    grand_total {\n                        currency\n                        value\n                    }\n                }\n                items {\n                    uid\n                    product {\n                        sku\n                        stock_status\n                    }\n                    quantity\n                }\n                applied_coupons {\n                    code\n                }\n                applied_gift_card {\n                    amount\n                    code\n                }\n                loyalty_points_info {\n                    point_rate\n                    points_earn\n                    points_spent\n                    points_discount\n                }\n            }\n        }\n    }\n"])));
// A query to fetch order details _right_ before we submit, so that we can pass
// data to the order confirmation page.
export var GET_ORDER_DETAILS = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    query getOrderDetails($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            ...OrderConfirmationPageFragment\n        }\n    }\n    ", "\n"])), OrderConfirmationPageFragment);
export var CALCULATE_LS_CART = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    mutation CalculateLSCart($cartId: String!) {\n        CalculateLSCart(cart_id: $cartId) {\n            cart {\n                id\n            }\n        }\n    }\n"])));
export var GET_CART_LOYALTY_POINTS = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    query getCartLoyaltyPoints($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            loyalty_points_info {\n                point_rate\n                points_earn\n                points_spent\n                points_discount\n            }\n        }\n    }\n"])));
export var GET_TAKEAWAY_AVAILABILITY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    query store_takeaway_availability($ls_store_id: String!) {\n        store_takeaway_availability(ls_store_id: $ls_store_id) {\n            timestamp\n            weekday\n            comment\n            time_caption\n            availability\n            price\n            price_currency\n            avail_date\n            avail_time\n        }\n    }\n"])));
export var GET_CHECKOUT_DETAILS = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    query getCheckoutDetails($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            ...CheckoutPageFragment\n        }\n    }\n    ", "\n"])), CheckoutPageFragment);
export var GET_SHIPPING_ADDRESS = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    query getSelectedShippingAddress($cartId: String!) {\n        cart(cart_id: $cartId) {\n            id\n            shippingAddresses: shipping_addresses {\n                firstName: firstname\n                lastName: lastname\n                country {\n                    code\n                }\n                street\n                city\n                region {\n                    code\n                }\n                postcode\n                phoneNumber: telephone\n            }\n        }\n    }\n"])));
export default {
  mutations: {
    createCartMutation: CREATE_CART,
    placeOrderMutation: PLACE_ORDER_ADYEN,
    setTakeawayDataOnCart: TAKEAWAY_DATA_ON_CART,
    applyLsLoyaltyPoints: APPLY_LS_LOYALTY_POINTS,
    removeLoyaltyPoints: REMOVE_LS_LOYALTY_POINTS,
    calculateLSCartMutation: CALCULATE_LS_CART
  },
  queries: {
    getCheckoutDetailsQuery: GET_CHECKOUT_DETAILS,
    getOrderDetailsQuery: GET_ORDER_DETAILS,
    getTakeAwayAvailability: GET_TAKEAWAY_AVAILABILITY,
    getShippingAddress: GET_SHIPPING_ADDRESS,
    getCartLoyaltyPoints: GET_CART_LOYALTY_POINTS
  }
};