export var salesTypeConfig = {
  CASUALDINING: {
    hasCovers: false,
    hasTimeslot: false,
    hasTable: true,
    hasCheckout: false,
    hasPay: true,
    hasPayAtStore: true,
    hasQueueCounter: false,
    hasCoverMessageFooter: true,
    goBackFromOrderMenu: "url.key.pay"
  },
  EATIN: {
    hasCovers: true,
    hasTimeslot: true,
    hasTable: false,
    hasCheckout: true,
    hasPay: false,
    hasPayAtStore: false,
    hasQueueCounter: false,
    hasCoverMessageFooter: false,
    goBackFromOrderMenu: "url.key.order"
  },
  FASTCASUALDINING: {
    hasCovers: true,
    hasTimeslot: false,
    hasTable: true,
    hasCheckout: true,
    hasPay: false,
    hasPayAtStore: false,
    hasQueueCounter: false,
    hasCoverMessageFooter: false,
    goBackFromOrderMenu: "url.key.app.home"
  },
  TAKEAWAY: {
    hasCovers: false,
    hasTimeslot: true,
    hasTable: false,
    hasCheckout: true,
    hasPay: false,
    hasPayAtStore: false,
    hasQueueCounter: true,
    hasCoverMessageFooter: false,
    goBackFromOrderMenu: "url.key.order"
  }
};